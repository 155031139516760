import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/2150895675.webp"
import image2 from "../Assets/1200.webp"
import image3 from "../Assets/air.webp"

import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'


function AirFreight() {
  return (
    <>
      <section className="page-title page-titles">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="index.html">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Air Freight</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>NexGen Logistics: Empowering Freight Solutions </h2>
                  <p>
                    NexGen Global Logistics offers reliable Air freight solutions, ensuring cost-effective and timely delivery for your shipments. With our extensive network and advanced tracking, we handle your logistics with precision and care, optimizing routes and minimizing transit times to meet your business needs efficiently.
                  </p>
                  <p>Optimize your supply chain with NexGen's Air freight services. We offer reliable, cost-effective solutions for shipping smaller loads worldwide. Benefit from our extensive network, advanced tracking, and commitment to timely delivery. Trust NexGen for efficient global logistics and unparalleled customer support. </p>

                  <p>NexGen specializes in Air Freight and Global Logistics, ensuring your shipments reach their destination efficiently and cost-effectively. Our advanced tracking systems and dedicated support team provide seamless logistics solutions worldwide. Trust NexGen for reliable, timely delivery and superior service in managing your freight needs.</p>
                  <h3>NextGen Logistics: Air Freight Specialists </h3>
                  <img src={image2} alt="" />
                  <p>NexGen offers top-tier Air freight solutions, ensuring efficient and cost-effective transportation for smaller shipments worldwide. Our global logistics network guarantees timely delivery, precise tracking, and unmatched reliability. Partner with NexGen to streamline your supply chain and meet all your shipping needs with confidence and ease. </p>
                  <h5>Are you searching for the best air freight services in the US? NexGen Global Logistics offers reliable and innovative solutions.</h5>
                  <p>At NexGen, we specialize in Air freight solutions, ensuring cost-effective and timely delivery of your shipments, no matter the size. Our extensive network and advanced tracking technology guarantee reliable service and real-time updates, making your logistics seamless. With a commitment to global reach, we handle international shipments with the same precision and care, navigating complex customs and regulations. Trust NexGen for your global logistics needs, and experience unparalleled efficiency and customer support. Whether local or international, we deliver excellence in every mile.</p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>NexGen Logistics: Seamlessly Navigate Air Freight Solutions </h6>
                      <p>
                        NexGen Global Logistics offers efficient Air freight solutions, combining shipments for cost-effective delivery. Trust us for reliable, timely, and secure transportation of your goods worldwide.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/suplly">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AirFreight;


