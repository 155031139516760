import './App.css';
import Footer from './Components/Navbar/Footer';
import Navbar from './Components/Navbar/Navbar';
import TopBar from './Components/Navbar/TopBar';
import Home from './Components/Pages/Home';
import { Route, Routes } from "react-router-dom";
import Contact from './Components/Pages/Contact';
import Service from './Components/Pages/Service';
import About from './Components/Pages/About';
import Payment from './Components/Pages/Payment';
import HeavyHaul from './Components/Pages/HeavyHaul';
import Warehousing from './Components/Pages/Warehousing​';
import SupplyChain from './Components/Pages/SupplyChain';
import LTLFreight from './Components/Pages/LTLFreight';
import Packaging from './Components/Pages/Packaging';
import Ocean from './Components/Pages/Ocean';
import 'remixicon/fonts/remixicon.css';
import CheckoutForm from './Components/Pages/CheckOutForm';
import Privacy from './Components/Pages/Privacy';
import Disclaimer from './Components/Pages/Disclaimer';


function App() {
  return (
  <>
  <TopBar />
  <Navbar />
  <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/heavy" element={<HeavyHaul />} />
        <Route path="/ware" element={<Warehousing />} />
        <Route path="/suplly" element={<SupplyChain />} />
        <Route path="/Lft" element={<LTLFreight />} />
        <Route path="/packaging" element={<Packaging />} />
        <Route path="/ocean" element={<Ocean />} />
        <Route path="/check" element={<CheckoutForm />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
  <Footer />  

  </>
  );
}

export default App;
