import React, { useEffect, useRef, useState } from 'react';
import service2 from "../Assets/service2-1.webp";
import service1 from "../Assets/truck2.webp";
import service3 from "../Assets/ship.webp";
import { Link } from 'react-router-dom';

const Common = () => {
  return (
    <>
      <section className="our-services  bg-pattern-4" >
      <div className="container ">
        <div className="section-second">
            <span data-aos="fade-right">We Offer Core Services</span>
            <h2 data-aos="fade-left">
              Prime logistics services for the world’s logistics Company.
            </h2>
            </div>
         
        <div className="row">
        <div className="service-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
            <div className="inner-box" data-aos="flip-right">
                <figure className="image" >
                    <img src={service1} alt="" style={{marginTop:"32px"}}/>
                </figure>
                <div className="content">
                    <h4 className="title">
                        <Link to="/heavy">Heavy Haul</Link>
                    </h4>
                    <div className="text">At the forefront of heavy haul transportation, we stand as undisputed experts. Our extensive fleet includes specialized equipment like lowboys, RGNs, perimeter trailers, and dual-lane trailers, catering to the most demanding haulage needs. Whether it’s oversized machinery, our solutions are tailored to ensure safe, efficient, and timely delivery.</div>
                </div>
            </div>
        </div>
        <div className="service-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
            <div className="inner-box" data-aos="flip-right">
                <figure className="image">
                    <img src={service2} alt="" />
                </figure>
                <div className="content">
                    <h4 className="title">
                        <Link to="/Lft">Air Freight</Link>
                    </h4>
                    <div className="text">We strategically collaborate with select carriers to provide cost-effective and rapid transportation solutions for your cargo. Our approach focuses on finding the most economical and fastest routes, ensuring your smaller shipments receive the same attention to detail and efficiency as larger loads</div>
                </div>
            </div>
        </div>
        <div className="service-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
            <div className="inner-box " data-aos="flip-right">
                <figure className="image">
                    <img src={service3} alt="" className='service-three'/>
                </figure>
                <div className="content">
                    <h4 className="title">
                        <Link to="/ocean">Ocean Freight</Link>
                    </h4>
                    <div className="text">At the heart of international logistics, our Ocean Freight service stands out for its efficiency and reliability. We specialize in managing the complexities of global shipping, offering both  Our extensive network and partnerships with major global carriers ensure competitive rates and timely delivery to ports across the world.</div>
                </div>
            </div>
        </div>
        
        </div>
        </div>
      </section> 
    </>
  )
}

export default Common
