import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/ocean1.webp";
import image2 from "../Assets/ocean2.webp"
import image3 from "../Assets/ocean3jpeg.webp";
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'


function Ocean() {
  return (
<>
    <section className="page-title page-titles">
    <div className="container">
      <div className="title-outer text-center">
        <h1 className="title">Services</h1>
        <ul className="page-breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <FaAngleRight />
          </li>
          <li>Ocean Freight</li>
        </ul>
      </div>
    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>NexGen Ocean Freight Logistics Solutions: Streamlined, Reliable, Global Services </h2>
                <p>
                NexGen: Your Gateway to Global Logistics Excellence. Explore our comprehensive ocean freight solutions tailored to your international shipping needs. With NexGen, experience seamless cargo transport across oceans, ensuring timely delivery and cost-effective solutions. Trust our expertise in navigating the complexities of global logistics. Partner with NexGen for efficient, reliable, and innovative shipping services.
                </p>
                <p>NexGen is your premier partner for ocean freight logistics on a global scale. With our extensive network and expertise, we deliver seamless solutions for your shipping needs. From efficient container management to reliable cargo tracking, our dedicated team ensures your goods reach their destination safely and on time. Experience excellence in ocean freight logistics with NexGen. </p>
                <p>NexGen: Your Gateway to Global Logistics Excellence. Explore our comprehensive ocean freight services for seamless international cargo transport. With NexGen, experience unparalleled efficiency and reliability in managing your global shipping needs. From efficient container handling to streamlined documentation, trust NexGen to deliver your goods across oceans with precision and professionalism. </p>
                <h3>NextGen Logistics: Ocean Freight Specialists </h3>
                <img src={image2} alt="" />
                <p>NexGen Ocean Freight Global Logistics is your premier partner for seamless international shipping solutions. With our extensive network and expertise in ocean freight logistics, we ensure your cargo reaches its destination efficiently and cost-effectively. From Full Container Load (FCL) to Less than Container Load (LCL) shipments, we offer tailored services to meet your specific needs. Our team of experienced professionals manages every aspect of the logistics process, including documentation, customs clearance, and cargo tracking, providing you with peace of mind throughout the journey. Choose NexGen for reliable, transparent, and timely ocean freight services that drive your business forward. </p>
                <h5>NexGen Logistics: Ocean Freight Specialists</h5>
                <p>NexGen, your gateway to seamless global logistics solutions. With our ocean freight services, we navigate the vast waters to connect your cargo to every corner of the world. Trust our expertise to handle your shipments efficiently, ensuring timely delivery and cost-effective solutions. Experience the future of logistics with NexGen, where innovation meets reliability. </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6> NexGen Logistics: Seamlessly Navigate Ocean Freight Solutions </h6>
                    <p>NexGen: Your gateway to seamless ocean freight solutions, connecting global logistics for smooth cargo transport across the seas. </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/suplly">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Ocean;


