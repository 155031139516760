import React from 'react'
import image1 from "../Assets/choose2-1.webp"
import choose2 from "../Assets/choose2-2.webp"
import delivery from "../Assets/tracking.webp"
import shield from "../Assets/shield.webp"

const Choose = () => {
  return (
    <>
      <section className="why-choose-us-two">
      <div className="bg bg-pattern-7"></div>
      <div className="container">
        <div className="row">

          <div className="content-column col-xl-6 col-lg-7 col-md-12 col-sm-12 wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
            <div className="inner-column" data-aos="zoom-in">
              <div className="sec-title light">
                <span className="sub-title text-white">Why choose us</span>
                <h2 className="scrub-each-word text-white text-split">
                Premier logistics solutions for the world's leading companies.
                </h2>
                <div className="text">We Provide a Full Range of Logistics and Transportation Services Worldwide</div>
              </div>
              <div className="title-box">
                <div className="image"><img src={choose2} alt='' /></div>
                <h5 className="title">NexGen Global Logistics Solution Provider <br />Since <span>1996</span></h5>
              </div>
              <div className="row g-0">
                <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                  <div className="inner-box">
                    <img src={shield} alt="" />
                    <div className="content">
                      <h6 className="title">Safety &amp; Reliable <br />Service</h6>
                    </div>
                  </div>
                </div>

                <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                  <div className="inner-box" data-aos="zoom-in-up">
                   <img src={delivery} alt=""  data-aos="zoom-in-up" />
                    <div className="content">
                      <h6 className="title">Real Time Tracking <br />System</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image-column col-xl-6 col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              <figure className="image"><img src={image1} alt='' /></figure>
              <div className="icon-text"></div>
            </div>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Choose
