import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Payment() {
  const [loadNumber, setLoadNumber] = useState('');
  const [amount, setAmount] = useState('');

  const handleLoadNumberChange = (e) => {
    setLoadNumber(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <div className="product">
      <Link to="#">
        <img
          width="500"
          height="auto"
          src="https://usravens.b-cdn.net/wp-content/uploads/2024/05/truck_img-1.webp"
          alt=""
          className='payment-image'
        />
      </Link>
      <div className='product-text'>
      <h3>Pay NexGen Global Logistics </h3>
        <p>Thanks for working with us. We appreciate our partnership.</p>
        <form onSubmit={handleSubmit}>
          <label className='product-label'>
            Load Number:
            <input type="number" value={loadNumber} className='product-input' onChange={handleLoadNumberChange} />
          </label>
          <label className='product-label'>
            Enter Amount ($):
            <input type="number" value={amount} className='product-input' onChange={handleAmountChange} />
          </label>
         
      <Link to="/check">   <button type="submit" className='single_add'>Buy now</button></Link> 
        </form>
      </div>
    </div>
  );
}

export default Payment;
