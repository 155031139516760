import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import image1 from "../Assets/disclaimer.png";

const Diclaimer = () => {
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">Disclaimer</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Disclaimer</li>
            </ul>
          </div>
        </div>
      </section>

        <div className="container">
      <div className="privacy">
        <div className="privacy-content">
        <h2>Disclaimer</h2>
        <p>
        "By providing my phone number to “NexGen Global Logistics”, I agree and acknowledge that “NexGen Global Logistics” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit {""} <Link to="/privacy-policy" className="privacy-link">PRIVACY POLICY</Link>  "
        </p>
        </div>
        <img src={image1} alt="" />
        
      </div>
      </div>
    </>
  );
};

export default Diclaimer;
