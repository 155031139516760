import React from 'react';
import work1 from "../Assets/work1-1.webp"
import work2 from "../Assets/choose2-1.webp"
import work3 from "../Assets/3_1.webp"
import work4 from "../Assets/about3-1.webp"


import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const PortfolioSection = () => {
    const projects = [
        {
          category: 'Transportation',
          title: 'Product Distribution',
          imgSrc:work1
        },
        {
          category: 'Transportation',
          title: 'Transport For Product',
          imgSrc:work2
        },
        {
          category: 'Transportation',
          title: 'Packing & Distribution',
          imgSrc:work3
        },
        {
          category: 'Transportation',
          title: 'Effective Packaging',
          imgSrc:work4
        },
      ];
  return (
    <section className="work-section">
      <div className="container">
        <div className="sec-title">
          <span className="sub-title">Our Portfolio</span>
          <h2 className="words-slide-up text-split">Explore Our Recent Work</h2>
        </div>
        <div className="outer-box">
          {projects.map((project, index) => (
            <div
              className={`work-block wow fadeInUp animated`}
              style={{
                visibility: 'visible',
                animationDelay: `${index * 300}ms`,
                animationName: 'fadeInUp'
              }}
              key={index}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                      <img src={project.imgSrc} alt="" />
                  </figure>
                </div>
                <div className="content-box">
                  <span className="cat">{project.category}</span>
                  <h4 className="title">
                    <Link to="/service">{project.title}</Link>
                  </h4>
                  <Link to="/service" className="read-more">
                  <FaAngleRight />
                  </Link>
                </div>
                <div className="content-box-hover">
                  <h4 className="title">
                   {project.title}
                  </h4>
                </div>
                <div className="overlay-1"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};



export default PortfolioSection;
