import React from 'react'
import process1 from "../Assets/process1-1.webp";
import process2 from "../Assets/process1-2.webp";
import process3 from "../Assets/process1-3.webp";
import process4 from "../Assets/process1-4.webp";
import { FaPhoneVolume } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Process = () => {
  return (
    <>
     <section className="process-section pt-0" data-aos="fade-down">
        <div className="bg bg-about"></div>
        <div className="icon-plane-6 bounce-y"></div>
        <div className="container">
          <div className="sec-about seconds-title">
            <span className="sub-icon"></span>
            <span className="sub-title">
              Customer Teams &amp; An Agile Services
            </span>
            <h2 className="words-slide-up text-split">
              Working Process For Services
            </h2>
          </div>
          <div className="outer-box">
            <div className="row">
              <div className="icon-line"></div>
              <div
                className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="inner-box">
                  <div className="icon-box-parent">
                    <div className="icon-box-about">
                      <figure className="image-about">
                        <img src={process1} alt="" />
                      </figure>
                      <span className="count">1</span>
                    </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Online Booking</h6>
                    <div className="text">
                    Welcome to our online booking system! Simplify your logistics process by scheduling your shipments effortlessly with just a few clicks
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="icon-box-parent">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process2} alt="" />
                    </figure>
                    <span className="count">2</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Select Freight Type</h6>
                    <div className="text">
                    Choose the most suitable freight type for your shipment with ease. Our intuitive platform offers a range of options tailored to meet your specific needs
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="600ms"
                style={{
                  visibility: "visible",
                  animationDelay: "600ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="icon-box-parent">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process3} alt="" />
                    </figure>
                    <span className="count">3</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Book Shipping</h6>
                    <div className="text">
                    Book your shipping needs effortlessly with our user-friendly platform. Whether you're sending packages across town or around the globe
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="900ms"
                style={{
                  visibility: "visible",
                  animationDelay: "900ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="icon-box-parent">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process4} alt="" />
                    </figure>
                    <span className="count">4</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Track Order</h6>
                    <div className="text">
                    Track your order effortlessly with our comprehensive tracking system. Simply enter your tracking number and get real-time updates on the status and location of your shipment. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box">
              <Link to="/contact" className="theme-btn btn-style-one">
                <span className="btn-title">Get a Quote</span>
              </Link>
              <Link to="tel:+1-877-200-9408" className="contact-btn">
                <FaPhoneVolume />
                +1-877-200-9408
              </Link>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default Process
