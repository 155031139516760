import AOS from "aos";
import "aos/dist/aos.css";
import video from "../Assets/video.mp4";
import { Link } from "react-router-dom";
import Testimonials from "./Testimonial";
import imageone from "../Assets/about3-1.webp";
import icon from "../Assets/courier.webp";
import icon2 from "../Assets/booking.webp";
import icon3 from "../Assets/logistic.webp";
import logistic from "../Assets/logistics.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaArrowAltCircleRight,
  FaPlaneDeparture,
  FaPrescriptionBottle,
  FaTruck,
} from "react-icons/fa";
import Choose from "./Choose";
import Counter from "./Counter";
import FormMain from "./Form";
import Process from "./Process";
import Common from "./Common";

const Home = () => {
  return (
    <>
      <div className="container-fluid home-main" data-aos="fade-in">
        <div className="row">
          <div className="col-md-12">
            <div
              className="video-overlay-container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <video
                src={video} 
                alt="House Shifting"
                data-aos="fade-in"
                data-aos-duration="2000"
                autoPlay
                muted
                loop
                width="100%" //
              >
             
              </video>
              <div
                className="overlay"
                data-aos="fade-in"
                data-aos-delay="300"
              ></div>
              <div className="text-overlay">
              <h1 data-aos="fade-down" data-aos-delay="500">
                  Get Started Logistic Services With NexGen Global Logisitcs 
                </h1>
                <p data-aos="fade-up" data-aos-delay="700">
                  If we drive down the cost of transportation in space, we can
                  do great things.
                </p>
                <div
                  className="slider-btn"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Link className="default-btn active" to="/service  ">
                    Our Services
                  </Link>
                  <Link className="default-btn white" to="/contact">
                    Request A Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section-three">
        <div className="icon-plane-2 bounce-y"></div>
        <div className="container ">
          <div className="row">
            <div className="content-column col-xl-4 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft animated">
              <div className="inner-column padding-home">
                <div className="sec-title" data-aos="fade-down-right">
                  <span className="sub-title">Get to know us</span>
                  <h2 className="scrub-each-word text-split">
                    Moving products across borders
                  </h2>
                </div>
                <h5 className="title">NexGen Global Logistics offers reliable and efficient cross-border shipping solutions.</h5>
                <div className="text" data-aos="fade-up-right">
                NexGen Global Logistics delivers comprehensive logistics and transportation services globally, ensuring seamless, innovative, and dependable solutions for all your shipping needs.
                </div>
                <div className="icon-box" data-aos="fade-down-left">
                  <img src={icon} alt="" />
                  <h5 className="title">
                  We Provide a Full Range of Global Logistics and Transportation Services
                  </h5>
                </div>
                <Link to="/about" className="theme-btn btn-style-one">
                  <span className="btn-title">Discover More</span>
                </Link>
              </div>
            </div>

            <div className="image-column col-xl-4 col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column" data-aos="fade-down-left">
                <figure className="image overlay-anim reveal">
                  <img src={imageone} alt=""  className="image-width"/>
                </figure>
                <div className="exp-box">
                  <div className="icon-building-2"></div>
                  <img src={logistic} alt=""/>
                  <span className="count">30</span>
                  <div className="text-light ">
                    Years of Shifting <br />
                    experience
                  </div>
                </div>
              </div>
            </div>

            <div className="funfact-column col-xl-4 col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column" data-aos="flip-left">
                <div className="fact-counter">
                  <div className="counter-block-two wow fadeInUp animated">
                    <div className="inner-box">
                      <img src={icon2} alt="" />
                      <div className="content">
                        <div className="counter-one">
                          <span
                            className="odometer odometer-auto-theme"
                            data-count="478"
                          >
                            478
                          </span>
                        </div>
                        <div className="counter-text">Projects Completed</div>
                      </div>
                    </div>
                  </div>

                  <div className="counter-block-two wow fadeInUp animated">
                    <div className="inner-box">
                      <img src={icon} alt="" />
                      <div className="content">
                        <div className="counter-one">
                          <span
                            className="odometer odometer-auto-theme"
                            data-count="650"
                          >
                            650
                          </span>
                        </div>
                        <div className="counter-text">Happy Customers</div>
                      </div>
                    </div>
                  </div>

                  <div className="counter-block-two wow fadeInUp animated">
                    <div className="inner-box">
                      <img src={icon3} alt="" />
                      <div className="content">
                        <div className="counter-one">
                          <span
                            className="odometer odometer-auto-theme"
                            data-count="900"
                          >
                            900
                          </span>
                        </div>
                        <div className="counter-text">Delivered in Time</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Common />

      <section className="service-area pt-100" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <span data-aos="fade-right">Our Core Services</span>
            
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
              <div className="single-service" alt="International Moving Abroad">
                <div className="service-content-wrap">
                  <FaTruck />
                  <h3>Moving Abroad</h3>
                  <p>
                    Relocate overseas with NexGen Global Logistics. Simplify your
                    Moving Abroad hassle-free. Expert assistance for smooth
                    transitions.
                  </p>
                </div>
                <div className="service-heading">
                  <Link className="circle-read-more" to="/heavy">
                    <h3>Moving Abroad</h3>
                    <FaArrowAltCircleRight />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
              <div
                className="single-service"
                alt="International freight company "
              >
                <div className="service-content-wrap">
                  <i className="icon flaticon-plastic-bottle"></i>
                  <FaPrescriptionBottle />
                  <h3>Ocean Freight</h3>
                  <p>
                    Transportation made easy. NexGen Global Logistics Reliable freight
                    Company for all your shipping needs. Efficient, secure, and
                    affordable.
                  </p>
                </div>
                <div className="service-heading">
                  <Link className="circle-read-more" to="/ocean">
                    <h3>Ocean Company</h3>
                    <FaArrowAltCircleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0"
              data-aos="fade-up"
            >
              <div
                className="single-service"
                alt="International freight delivery"
              >
                <div className="service-content-wrap">
                  <i className="icon flaticon-air-freight"></i>
                  <FaPlaneDeparture />
                  <h3>Air Freight</h3>
                  <p>
                    Fast, efficient freight delivery solution. Reliable and
                    secure transport for all your shipping needs. Trust  NexGen Global Logistics
                  </p>
                </div>
                <div className="service-heading">
                  <Link className="circle-read-more" to="/Lft">
                  <h3>Air Freight</h3>
                    
                    <FaArrowAltCircleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12" data-aos="fade-up">
              <div className="service-all mt-4">
                <p>
                  The schedule is also available.
                  <Link to="/contact"> Get a callback time slot</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Choose />
      <Testimonials />
      <Counter />
      <Process />
      <FormMain />
    </>
  );
};

export default Home;
