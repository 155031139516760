import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import cta1 from "../Assets/cta1-1.webp";
import service1 from "../Assets/roadways.webp";
import service2 from "../Assets/service1-1.webp";
import service3 from "../Assets/service1-2.webp";
import service4 from "../Assets/truck.webp";
import service5 from "../Assets/packers.webp";
import service6 from "../Assets/women.webp";
import { Link } from 'react-router-dom';
import FormMain from './Form';

const Service = () => {
  return (
    <>
      <section className="page-title page-titles">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Services</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="services-section">
        <div className="container">
          <div className="outer-box-service">
            <h2>Our Comprehensive Suite Of Services Is Designed To Streamline Your Supply Chain, Ensuring Your Goods Are Transported, Stored, And Managed With The Utmost Care And Precision.</h2>
            <div className="services-grid">
              
              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service1} alt="Service" />
                  <div className="content-service">
                    <h4 className="title-service">
                      <Link to="/heavy">Heavy Haul</Link>
                    </h4>
                    <div className="text-service">
                      At the forefront of heavy haul transportation, we stand as undisputed experts. Our extensive fleet includes specialized equipment like lowboys, RGNs, perimeter trailers, and dual-lane trailers, catering to the most demanding haulage needs. Whether it’s oversized machinery or exceptionally heavy loads, our solutions are tailored to ensure safe, efficient, and timely delivery.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service2} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-ship"></i>
                    <h4 className="title-service">
                      <Link to="/Lft">Air Freight</Link>
                    </h4>
                    <div className="text-service">
                      We strategically collaborate with select carriers to provide cost-effective and rapid transportation solutions for your cargo. Our approach focuses on finding the most economical and fastest routes, ensuring your smaller shipments receive the same attention to detail and efficiency as larger loads.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service3} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/ocean">Ocean Freight</Link>
                    </h4>
                    <div className="text-service">
                      At the heart of international logistics, our Ocean Freight service stands out for its efficiency and reliability. We specialize in managing the complexities of global shipping, offering both Less-than-Container Load (LCL) and Full Container Load (FCL) options to suit diverse needs. Our extensive network and partnerships with major global carriers ensure competitive rates and timely delivery to ports across the world.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service4} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/ware">Drayage And Warehousing​</Link>
                    </h4>
                    <div className="text-service">
                      Experience unparalleled drayage and warehousing services with our vast network of over eight thousand trucks and scalable warehousing capabilities. We specialize in providing immediate capacity for drayage needs and offer comprehensive warehousing solutions with a range of value-added services. Our commitment to quality and reliability.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service5} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/suplly">Supply Chain</Link>
                    </h4>
                    <div className="text-service">
                      In the ever-evolving world of commerce, a robust supply chain is vital. Our Supply Chain Management service is designed to optimize every aspect of your logistics process. From sourcing and procurement to distribution and returns, we provide end-to-end solutions that are both efficient and cost-effective. Partner with us to transform your supply chain into a strategic asset.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service6} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/packaging">Packaging</Link>
                    </h4>
                    <div className="text-service">
                      Recognizing the importance of safe and secure packaging, we offer comprehensive Packaging Solutions tailored to your products. From standard boxes to custom-designed packaging, our focus is on ensuring that your goods are not only well-protected but also presented in a way that enhances your brand. Let us handle the details of packaging, so you can focus on what you do best.
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="bg bg-pattern-1">
            <div className="content-box-contact">
              <h2 className="title words-slide-up text-split">
                24/7 customer support any time of the day or night
              </h2>
              <div className="text">
                Customers can get help and find answers to questions as soon
              </div>
              <div className="btn-box-contact">
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">Request a Quote</span>
                </Link>
                <Link to="/contact" className="theme-btn btn-style-one light-bg">
                  <span className="contact">Contact Us</span>
                </Link>
              </div>
            </div>
            <div className="image-box-contact">
              <img src={cta1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <FormMain />
    </>
  );
}

export default Service;
