import React from "react";
import { FaAngleRight } from "react-icons/fa";
import about1 from "../Assets/about1-1.webp";
import about2 from "../Assets/about1-2.webp";
import delivery from "../Assets/delivery.webp";
import Testimonials from "./Testimonial";
import air from "../Assets/airplane.webp"
import book from "../Assets/book.webp"
import logistic2 from "../Assets/logistic.webp"
import logistic from "../Assets/logistics.webp"

import FormMain from "./Form";
import PortfolioSection from "./PortfolioSection";
import Process from "./Process";
import { Link } from 'react-router-dom';
const About = () => {
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">About Us</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="icon-plane-8 bounce-x mt-5"></div>
        <div className="icon-shape bounce-y"></div>
        <div className="container">
          <div className="row">
            <div
              className="content-column col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight animated"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInRight",
              }}
            >
              <div className="inner-column-about">
                <div className="seconds-title">
                  <span className="sub-title">
                    Customer Teams &amp; An Agile Services
                  </span>
                  <h2 className="words-slide-up text-split">
                    NexGen Global Logistics Partner To World’s Famous Brands 
                    <span className="color">{""} In US!</span>
                  </h2>
                  <p className="text text-justify">
                  NexGen Global Logistics is a leading logistics company dedicated to providing innovative and efficient solutions for businesses worldwide. Specializing in supply chain management, transportation, and warehousing services, NexGen offers a comprehensive range of logistics solutions tailored to meet the diverse needs of its clients. With a commitment to excellence and customer satisfaction, NexGen leverages cutting-edge technology and a global network of partners to ensure the seamless movement of goods from origin to destination. Trusted by businesses of all sizes, NexGen Global Logistics is your reliable partner for streamlined logistics operations.
                  </p>
                </div>
              </div>
            </div>

            <div className="image-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column-about">
                <div className="image-box">
                  <img
                    src={about1}
                    alt=""
                    style={{ transform: "translate(0px, 0px)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="content-column style-two col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight animated"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInRight",
              }}
            >
              <div className="inner-about">
                <div className="row">
                  <div className="about-block col-lg-6 col-md-3 col-sm-6">
                    <div className="inner-box-order">
                     <div className="inner-box-order-two">
                     <img src={air} alt="" className="icon"/>
                     </div>
                      <h5 className="title">Freight Transportation</h5>
                      <div className="text">
                      Types of freight services (air, sea, land)
Coverage areas and key routes
Special services (e.g., expedited shipping, fragile goods handling)
                      </div>
                    </div>
                  </div>
                  <div className="about-block col-lg-6 col-md-3 col-sm-6">
                  <div className="inner-box-order">
                     <div className="inner-box-order-two">
                     <img src={book} alt="" className="icon"/>
                     </div>
                      <h5 className="title">Supply Chain Management</h5>
                      <div className="text">
                      Inventory management
Warehousing solutions
Distribution services
                      </div>
                    </div>
                  </div>
                  <div className="about-block col-lg-6 col-md-3 col-sm-6">
                    <div className="inner-box-order">
                     <div className="inner-box-order-two">
                     <img src={logistic} alt="" className="icon"/>
                     </div>
                      <h5 className="title">Sustainability Initiatives</h5>
                      <div className="text">
                      Environmental Responsibility
Green logistics practices
Social Responsibility
Employee welfare initiatives
                      </div>
                    </div>
                  </div>
                  <div className="about-block col-lg-6 col-md-3 col-sm-6">
                  <div className="inner-box-order">
                     <div className="inner-box-order-two">
                     <img src={logistic2} alt="" className="icon"/>
                     </div>
                      <h5 className="title">Fast, Efficient Delivery</h5>
                      <div className="text">
                        Following the quality of our service thus having gained
                        trust of our many clients.
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>

            <div className="image-column style-two col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column-about">
                <div className="image-box">
                  <figure
                    className="image overlay-anim reveal"
                    style={{
                      transform: "translate(0px, 0px)",
                      opacity: "1",
                      visibility: "inherit",
                    }}
                  >
                    <img
                      src={about2}
                      alt=""
                      style={{ transform: "translate(0px, 0px)" }}
                    />
                  </figure>
                  <div className="exp-box-second bounce-y">
                    <img src={delivery} alt="" />
                    <h3 className="title">9.612 m</h3>
                    <span>Delivered Goods</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<Process />

<PortfolioSection />
      
<Testimonials />
      <FormMain />
    </>
  );
};

export default About;
