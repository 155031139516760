import React from 'react'
import FormMain from './Form'

const Contact = () => {
  return (
    <>
      <FormMain />
      <div className="map-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.2440518069957!2d-97.80974262463712!3d30.485842974704273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b2d67642df483%3A0xe323379d75d79859!2s1400%20Avery%20Ranch%20Blvd%20c200%2C%20Cedar%20Park%2C%20TX%2078613%2C%20USA!5e0!3m2!1sen!2sin!4v1717758615590!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    </>
  )
}

export default Contact
