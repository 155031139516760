
import { useState, useEffect } from "react";


const Counter = () => {
    const [statistics, setStatistics] = useState({
      javascriptExperts: 0,
      yearsExperience: 55,
      clientRetention: 65,
      revenueGrowth: 4,
    });
  
    useEffect(() => {
      const interval = setInterval(() => {
        setStatistics((prevStats) => ({
          javascriptExperts:
            prevStats.javascriptExperts < 40
              ? prevStats.javascriptExperts + 1
              : prevStats.javascriptExperts,
          yearsExperience:
            prevStats.yearsExperience < 100
              ? prevStats.yearsExperience + 1
              : prevStats.yearsExperience,
          clientRetention:
            prevStats.clientRetention < 105
              ? prevStats.clientRetention + 1
              : prevStats.clientRetention,
          revenueGrowth:
            prevStats.revenueGrowth < 50
              ? prevStats.revenueGrowth + 1
              : prevStats.revenueGrowth,
        }));
      }, 100);
  
      return () => clearInterval(interval);
    }, []);
  
    const { javascriptExperts, yearsExperience, clientRetention, revenueGrowth } =
      statistics;
  
    return (
      <div className=" bg-dark text-white overflow-hidden">
          <svg
            className="absolute "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#000"
              fillOpacity="0.2"
              d="M0,320L40,293.3C80,267,160,213,240,192C320,171,400,181,480,192C560,203,640,213,720,197.3C800,181,880,139,960,112C1040,85,1120,75,1200,96C1280,117,1360,171,1400,197.3L1440,224L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
            ></path>
          </svg>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="text-center">
                <span className="text-4xl font-weight-bold">{javascriptExperts}+</span>
                <p className="mt-2">Delivered Projects</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center">
                <span className="text-4xl font-weight-bold">{yearsExperience}+</span>
                <p className="mt-2">Ongoing Projects</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center">
                <span className="text-4xl font-weight-bold">{clientRetention}%</span>
                <p className="mt-2">Acres of Development</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center">
                <span className="text-4xl font-weight-bold">{revenueGrowth}x</span>
                <p className="mt-2">Happy Families</p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    );
  };
  
  export default Counter;